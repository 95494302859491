<template>
<div>
    <div class="title mb-4 container-fluid">
        <div class="row">
            <div class="col-12">
                Registro al Programa de Formación
                <div class="float-md-right pt-2 pt-md-0">
                    <img src="/images/logo-igualdad-sustantiva-gris.png">
                    <img src="/images/logo-gobierno.de.jalisco.svg" height="50px">
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid" v-if="step === -1">
        <div class="row justify-content-center no-gutters">
            <div class="col-12">
                <main>
                    <div class="box-shadow">
                        <div class="tab">
                            <div class="row">
                                <div class="col-12 form-intro">
                                    <div class="row justify-content-center no-gutters">
                                        <div class="col-lg-9 col-10 t-black-two ff-nutl">
                                            <h2 class="col-11 px-0 f-w700 ff-nutB mt-5 text-center">¡Bienvenida Empresa!<br>
                                                Este es tu registro al Programa de Formación impartido por la Secretaría de Igualdad Sustantiva entre Mujeres y Hombres (SISEMH).
<br><br>
                                                Un espacio de charlas lúdicas y participativas de sensibilización para quienes buscan impulsar la igualdad de género desde el ámbito laboral.

                                            </h2>
                                            <div class="my-5 text-center">
                                                <a href="javascript:void(0)" class="button b-green-blue t-white w-100 text-center" @click.prevent="next">Comenzar <i class="fas fa-angle-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </div>
    <div class="container-fluid" id="diag-intro" v-if="step === 0">
        <div class="row justify-content-center no-gutters">
            <div class="col-12">
                <main>
                    <div class="box-shadow">
                        <div class="tab">
                            <div class="row">
                                <div class="col-lg-5 col-12 form-intro-amethyst">
                                    <div class="row justify-content-center no-gutters">
                                        <div class="col-lg-9 col-10 t-black-two ff-nutl">
                                            <h2 class="col-11 px-0 f-w700 ff-nutB mt-5">¿En qué consiste el Programa de Formación?</h2>
                                            <p class="mb-4">
                                                El Programa de Formación impartido por la SISEMH, consta de tres módulos y dos actividades complementarias, para las que se requiere una inversión de tiempo total aproximado de 8 horas. Esto con el propósito de que las empresas y/o centros de trabajo, puedan establecer las bases para construir una cultura de igualdad de género desde un modelo formativo de reflexión y sensibilización, que tenga un impacto significativo dentro de sus espacios laborales u otros entornos a los que pertenecen.
                                            </p>
                                            <p>
                                                El Programa se brindará en horarios fijos una vez por semana en modalidad virtual. En caso de que la empresa requiera obtener este proceso formativo de manera presencial, se deberá de contar con un número de asistentes mayor a 25 personas, para las empresas que se encuentren en Zona Metropolitana, y mayor a 50 personas, para las empresas que se encuentren al interior del estado de Jalisco.
                                            </p>
                                            <p>
                                                La gestión para la modalidad presencial, se deberá de realizar al correo: <a href="mailto:vinculacionempresarial.sisemh@jalisco.gob.mx"> vinculacionempresarial.sisemh@jalisco.gob.mx</a>, para revisar la disponibilidad en la agenda de la SISEMH, y proporcionar la atención y seguimiento.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-7 col-12 form-intro">
                                    <div class="row justify-content-center">
                                        <div class="col-xl-9 col-md-11 col-10 t-brownish-grey">
                                            <p class="f-w700 my-5">El contenido temático y el número de horas que incluye el Programa son los siguientes:</p>
                                            <div class="table-responsive">
                                                <table class="table d-none d-md-block">
                                                    <thead style="background-color: rgba(145, 163, 227, 0.6);">
                                                    <tr style="color: #252a4b; border: 1px solid rgba(145, 163, 227, 0.6);">
                                                        <th style="border: 1px solid rgba(145, 163, 227, 0.6);">Programa de Formación</th>
                                                        <th class="text-center" style="border: 1px solid rgba(145, 163, 227, 0.6);">Modalidad</th>
                                                        <th class="text-center" style="border: 1px solid rgba(145, 163, 227, 0.6);">Día(s)</th>
                                                        <th class="text-center" style="border: 1px solid rgba(145, 163, 227, 0.6);">Tiempo</th>
                                                        <th class="text-center" style="border: 1px solid rgba(145, 163, 227, 0.6);">Horario</th>
                                                        <th class="text-center" style="border: 1px solid rgba(145, 163, 227, 0.6);">Público Objetivo</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody style="font-size: 16px;">
                                                    <tr>
                                                        <td style="border: 1px solid rgba(145, 163, 227, 0.6); background-color: rgba(145, 163, 227, 0.15)">
                                                            <span class="f-w700">Módulo 1</span><br>
                                                            <span>Tema: Discriminación</span><br>
                                                            Titulo: <i>La hora de la igualdad</i></td>
                                                        <td class="text-center" style="border: 1px solid rgba(145, 163, 227, 0.6);">virtual</td>
                                                        <td class="text-center" style="border: 1px solid rgba(145, 163, 227, 0.6);">Martes</td>
                                                        <td class="text-center" style="border: 1px solid rgba(145, 163, 227, 0.6);">2 hrs.</td>
                                                        <td class="text-center" style="border: 1px solid rgba(145, 163, 227, 0.6);">10:00 a 12:00 hrs.</td>
                                                        <td class="text-center" style="border: 1px solid rgba(145, 163, 227, 0.6);">Personal de la Empresa</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="border: 1px solid rgba(145, 163, 227, 0.6); background-color: rgba(145, 163, 227, 0.15)">
                                                            <span class="f-w700">Actividad complementaria 1</span><br>
                                                            <span>Tema: Cuestionamiento de los estereotipos de género</span><br>
                                                            Titulo: <i>Todos deberíamos ser feministas</i>
                                                        </td>
                                                        <td class="text-center" style="border: 1px solid rgba(145, 163, 227, 0.6);">Virtual</td>
                                                        <td class="text-center" style="border: 1px solid rgba(145, 163, 227, 0.6);">Libre</td>
                                                        <td class="text-center" style="border: 1px solid rgba(145, 163, 227, 0.6);">45 min.</td>
                                                        <td class="text-center" style="border: 1px solid rgba(145, 163, 227, 0.6);">Libre</td>
                                                        <td class="text-center" style="border: 1px solid rgba(145, 163, 227, 0.6);">Personal de la Empresa</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="border: 1px solid rgba(145, 163, 227, 0.6); background-color: rgba(145, 163, 227, 0.15)">
                                                            <span class="f-w700">Módulo 2</span><br>
                                                            <span>Tema: Igualdad de Género</span><br>
                                                            Titulo: <i>La hora de la igualdad</i>
                                                        </td>
                                                        <td class="text-center" style="border: 1px solid rgba(145, 163, 227, 0.6);">virtual</td>
                                                        <td class="text-center" style="border: 1px solid rgba(145, 163, 227, 0.6);">Miércoles</td>
                                                        <td class="text-center" style="border: 1px solid rgba(145, 163, 227, 0.6);">2 hrs.</td>
                                                        <td class="text-center" style="border: 1px solid rgba(145, 163, 227, 0.6);">10:00 a 12:00 hrs.</td>
                                                        <td class="text-center" style="border: 1px solid rgba(145, 163, 227, 0.6);">Personal de la Empresa</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="border: 1px solid rgba(145, 163, 227, 0.6); background-color: rgba(145, 163, 227, 0.15)">
                                                            <span class="f-w700">Actividad complementaria 2</span><br>
                                                            <span>Tema: Violencia de Género</span><br>
                                                            Titulo: <i>Hagamos frente a la violencia</i>
                                                        </td>
                                                        <td class="text-center" style="border: 1px solid rgba(145, 163, 227, 0.6);">Virtual</td>
                                                        <td class="text-center" style="border: 1px solid rgba(145, 163, 227, 0.6);">Libre</td>
                                                        <td class="text-center" style="border: 1px solid rgba(145, 163, 227, 0.6);">15 min.</td>
                                                        <td class="text-center" style="border: 1px solid rgba(145, 163, 227, 0.6);">Libre</td>
                                                        <td class="text-center" style="border: 1px solid rgba(145, 163, 227, 0.6);">Personal de la Empresa</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="border: 1px solid rgba(145, 163, 227, 0.6); background-color: rgba(145, 163, 227, 0.15)">
                                                            <span class="f-w700">Módulo 3</span><br>
                                                            <span>Tema: Violencia de Género, Acoso sexual y Hostigamiento Sexual</span><br>
                                                            Titulo: <i>Violencia de Género, Acoso sexual y Hostigamiento Sexual en Empresas: prevención y detección</i>
                                                        </td>
                                                        <td class="text-center" style="border: 1px solid rgba(145, 163, 227, 0.6);">virtual</td>
                                                        <td class="text-center" style="border: 1px solid rgba(145, 163, 227, 0.6);">Jueves</td>
                                                        <td class="text-center" style="border: 1px solid rgba(145, 163, 227, 0.6);">3 hrs.</td>
                                                        <td class="text-center" style="border: 1px solid rgba(145, 163, 227, 0.6);">10:00 a 13:00 hrs.</td>
                                                        <td class="text-center" style="border: 1px solid rgba(145, 163, 227, 0.6);">Personal de la Empresa</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="d-block d-md-none">
                                                <div class="b-perrywinkle ff-nutB t-black text-center p-2" style="min-height: 42px; border: 1px solid rgba(145, 163, 227, 0.6);">
                                                    Programa de Formación
                                                </div>
                                                <div class="p-2 ff-karla" style="border: 1px solid rgba(145, 163, 227, 0.6); background-color: rgba(145, 163, 227, 0.15)">
                                                    <span class="f-w700">Módulo 1</span><br>
                                                    <span>Tema: Discriminación</span><br>
                                                    Titulo: <i>La hora de la igualdad</i>
                                                </div>
                                                <div class="p-2 ff-karla" style="border: 1px solid rgba(145, 163, 227, 0.6); background-color: rgba(145, 163, 227, 0.05)">
                                                    <p><span class="f-w700">Modalidad</span> Virtual</p>
                                                    <p><span class="f-w700">Día</span> Martes</p>
                                                    <p><span class="f-w700">Tiempo</span> 2 hrs.</p>
                                                    <p><span class="f-w700">Horario</span> 10:00 a  12:00 hrs.</p>
                                                    <p><span class="f-w700">Público Objetivo</span> Personal de la Empresa</p>
                                                </div>
                                                <div class="p-2 ff-karla" style="border: 1px solid rgba(145, 163, 227, 0.6); background-color: rgba(145, 163, 227, 0.15)">
                                                    <span class="f-w700">Actividad complementaria 1</span><br>
                                                    <span>Tema: Cuestionamiento de los estereotipos de género</span><br>
                                                    Titulo: <i>Todos deberíamos ser feministas</i>
                                                </div>
                                                <div class="p-2 ff-karla" style="border: 1px solid rgba(145, 163, 227, 0.6); background-color: rgba(145, 163, 227, 0.05)">
                                                    <p><span class="f-w700">Modalidad</span> Virtual</p>
                                                    <p><span class="f-w700">Día</span> Libre</p>
                                                    <p><span class="f-w700">Tiempo</span> 45 min.</p>
                                                    <p><span class="f-w700">Horario</span> Libre</p>
                                                    <p><span class="f-w700">Público Objetivo</span> Personal de la Empresa</p>
                                                </div>
                                                <div class="p-2 ff-karla" style="border: 1px solid rgba(145, 163, 227, 0.6); background-color: rgba(145, 163, 227, 0.15)">
                                                    <span class="f-w700">Módulo 2</span><br>
                                                    <span>Tema: Igualdad de género</span><br>
                                                    Titulo: <i>La hora de la igualdad</i>
                                                </div>
                                                <div class="p-2 ff-karla" style="border: 1px solid rgba(145, 163, 227, 0.6); background-color: rgba(145, 163, 227, 0.05)">
                                                    <p><span class="f-w700">Modalidad</span> Virtual</p>
                                                    <p><span class="f-w700">Día</span> Miércoles</p>
                                                    <p><span class="f-w700">Tiempo</span> 2 hrs.</p>
                                                    <p><span class="f-w700">Horario</span> 10:00 a  12:00 hrs.</p>
                                                    <p><span class="f-w700">Público Objetivo</span> Personal de la Empresa</p>
                                                </div>
                                                <div class="p-2 ff-karla" style="border: 1px solid rgba(145, 163, 227, 0.6); background-color: rgba(145, 163, 227, 0.15)">
                                                    <span class="f-w700">Actividad complementaria 2</span><br>
                                                    <span>Tema: Violencia de género</span><br>
                                                    Titulo: <i>Hagamos frente a la violencia</i>
                                                </div>
                                                <div class="p-2 ff-karla" style="border: 1px solid rgba(145, 163, 227, 0.6); background-color: rgba(145, 163, 227, 0.05)">
                                                    <p><span class="f-w700">Modalidad</span> Virtual</p>
                                                    <p><span class="f-w700">Día</span> Libre</p>
                                                    <p><span class="f-w700">Tiempo</span> 15 min.</p>
                                                    <p><span class="f-w700">Horario</span> Libre</p>
                                                    <p><span class="f-w700">Público Objetivo</span> Personal de la Empresa</p>
                                                </div>
                                                <div class="p-2 ff-karla" style="border: 1px solid rgba(145, 163, 227, 0.6); background-color: rgba(145, 163, 227, 0.15)">
                                                    <span class="f-w700">Módulo 3</span><br>
                                                    <span>Tema: Violencia de género, Acoso sexual y Hostigamiento Sexual</span><br>
                                                    Titulo: <i>Violencia de género, Acoso sexual y Hostigamiento Sexual en Empresas: prevención y detección</i>
                                                </div>
                                                <div class="p-2 ff-karla" style="border: 1px solid rgba(145, 163, 227, 0.6); background-color: rgba(145, 163, 227, 0.05)">
                                                    <p><span class="f-w700">Modalidad</span> Virtual</p>
                                                    <p><span class="f-w700">Día</span> Jueves</p>
                                                    <p><span class="f-w700">Tiempo</span> 3 hrs.</p>
                                                    <p><span class="f-w700">Horario</span> 10:00 a  13:00 hrs.</p>
                                                    <p><span class="f-w700">Público Objetivo</span> Personal de la Empresa</p>
                                                </div>
                                            </div>
                                            <p>
                                                Es necesario resaltar que el <strong>Cumplimiento</strong> de los tres módulos son de vital importancia, ya que a través de este modelo integral se genera la sensibilización, y se adquieren los conocimientos básicos necesarios para comprender la igualdad de género y prevenir casos de acoso y hostigamiento sexual en espacios laborales.

                                            </p>
                                            <div class="my-5 text-right">
                                                <a href="javascript:void(0)" class="button b-green-blue t-white w-100 text-center" @click.prevent="next">Comenzar <i class="fas fa-angle-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </div>


    <div class="container-fluid" id="diag-form" v-if="step >= 1">
        <div class="row justify-content-center no-gutters">
            <div class="col-12 col-lg-11">
                <main>
                    <div id="register-form" class="box-shadow_padding mb-5">
                        <div class="row justify-content-center my-4">
                            <div class="col-12 col-lg-11">
                                <!-- Progress Bar -->
                                <ul id="diagnostic_progressbar">
                                    <li class="step" :class="[(step === 1 ? ' active': ''), (step > 1 ? ' complete': '')]">01</li>
                                    <li class="step" :class="[(step === 2 ? ' active': ''), (step > 2 ? ' complete': '')]">02</li>
                                    <li class="step" :class="[(step === 3 ? ' active': ''), (step > 3 ? ' complete': '')]">03</li>
                                    <li class="step" :class="[(step === 4 ? ' active': ''), (step > 4 ? ' complete': '')]">04</li>
                                    <li class="step" :class="[(step === 5 ? ' active': ''), (step > 5 ? ' complete': '')]">05</li>
                                </ul>
                                <!-- /Progress Bar -->
                                <div class="alert alert-danger print-error-msg" v-if="Object.keys(this.errors).length">
                                    ¡Existen errores!
                                </div>
                                <!-- TAB -- 1 -->

                                <div class="tab" v-if="step === 1">
                                    <div class="diagnose-title">Pactemos por la Igualdad</div>
                                    <div class="row">
                                        <div class="col-12 ff-nutL">
                                            <p>
                                                Este Programa de Formación está ligado al proyecto "Pactemos por la Igualdad", el cual incluye los tres módulos y las dos actividades complementarias que se mencionaron previamente, y se complementa con un cuarto módulo específico para la empresa participante al proyecto.
                                            </p>
                                            <p>
                                                Si te interesa conocer de qué trata el proyecto consulta la información en nuestra página <a href="http://pactemos.jalisco.gob.mx/">http://pactemos.jalisco.gob.mx/</a>, desde la que podrás registrarte y ser parte de la Red de Empresas PACTEMOS, así como recibir Asesoría Personalizada y obtener un Kit de Herramientas con toda la información general y específica para construir espacios de trabajo más igualitarios.
                                            </p>
                                        </div>
                                        <div class="col-12 mt-3">
                                            <span class="mb-2 d-block">¿Te gustaría saber más sobre Pactemos por la Igualdad?<i class="t-faded-red">*</i></span>
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" class="custom-control-input" v-model="more_info" value="1" id="more_info_1">
                                                <label class="custom-control-label" for="more_info_1">Sí</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" class="custom-control-input" v-model="more_info" value="0" id="more_info_2">
                                                <label class="custom-control-label" for="more_info_2">No</label>
                                            </div>
                                            <label class="error" v-if="this.errors.more_info">
                                                {{ this.errors.more_info }}
                                            </label>
                                        </div>

                                        <div class="col-12 mt-5">
                                            <span class="mb-2 d-block">¿Qué registro te interesa?<i class="t-faded-red">*</i></span>
                                            <div class="custom-control custom-radio">
                                                <input type="radio" class="custom-control-input" v-model="register" value="1" id="register_pactemos">
                                                <label class="custom-control-label" for="register_pactemos">
                                                    Me interesa participar en "Pactemos por la Igualdad" y cumplir con los 4 módulos del Programa de Formación
                                                </label>
                                            </div>
                                            <div class="custom-control custom-radio">
                                                <input type="radio" class="custom-control-input" v-model="register" value="0" id="register_formacion">
                                                <label class="custom-control-label" for="register_formacion">
                                                    Me interesa únicamente el registro del Programa de Formación de los 3 módulos
                                                </label>
                                            </div>
                                            <label class="error" v-if="this.errors.register">
                                                {{ this.errors.register }}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-lg-6 col-12">1 de 5</div>
                                        <div class="col-lg-6 col-12 text-right">
                                            <button type="button" class="button t-white b-perrywinkle" @click.prevent="prev"><i class="fas fa-angle-left"></i> Regresar</button>
                                            <button type="button" class="button t-white b-amber" @click.prevent="next">Continuar <i class="fas fa-angle-right"></i></button>
                                        </div>
                                    </div>
                                </div>

                                <!-- TAB -- 2 -->
                                <div class="tab" v-if="step === 2">
                                    <div class="diagnose-title">Aviso de Privacidad</div>
                                    <p class="mb-5 ff-nutL f-s15">La SISEMH es la responsable del tratamiento de los datos personales que nos proporcione, los cuales serán utilizados para las siguientes finalidades:<br><br>
                                        a) Registrar su interés en la participación de los Talleres de Formación;<br><br>
                                        b) Establecer comunicación para dar seguimiento a su solicitud de participación o aclaración de dudas sobre sus datos y/o notificación del inicio de los talleres o el proyecto Pactemos;<br><br>
                                        c) Generar estadísticas, envío de material de exposición o apoyo;<br><br>
                                        d) Generar listas de asistencias y validación de las mismas;<br><br>
                                        e) Invitación a futuros eventos.<br><br>
                                        Se informa que no se realizarán transferencias que requieran su consentimiento, salvo aquellas que sean necesarias para atender requerimientos de información de una autoridad competente, debidamente fundamentados. Durante los talleres, se recabará evidencia fotográfica y testimonial con objetivos estadísticos.<br><br>
                                        Para mayor información acerca del tratamiento y de los derechos que puede hacer valer, acceda al aviso de privacidad integral publicado en el portal de transparencia de la Secretaría de Igualdad Sustantiva entre Mujeres y Hombres, ubicado en: <a href="https://transparencia.info.jalisco.gob.mx/transparencia/dependencia/370">https://transparencia.info.jalisco.gob.mx/transparencia/dependencia/370</a></p>
                                    <div class="row">
                                        <div class="col-12 mt-3">
                                            <span class="mb-2 d-block">He leído y entiendo ampliamente la utilización que se les dará a mis datos privados y doy mi consentimiento<i class="t-faded-red">*</i></span>
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" v-model="privacy" value="1" id="privacy">
                                                <label class="custom-control-label" for="privacy"></label>
                                                <label class="error" v-if="this.errors.privacy">
                                                    {{ this.errors.privacy }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-5">
                                        <div class="col-lg-6 col-12">2 de 5</div>
                                        <div class="col-lg-6 col-12 text-right">
                                            <button type="button" class="button t-white b-perrywinkle" @click.prevent="prev"><i class="fas fa-angle-left"></i> Regresar</button>
                                            <button type="button" class="button t-white b-amber" @click.prevent="next">Continuar <i class="fas fa-angle-right"></i></button>
                                        </div>
                                    </div>
                                </div>

                                <!--TAB -- 3-->
                                <div class="tab" v-if="step === 3">
                                    <div class="diagnose-title">Agendar</div>
                                    <div class="row">
                                        <div class="col-12 ff-nutL">
                                            <p>
                                                El Programa de Formación, que consta de 3 módulos, cuenta con una duración de una semana y se impartirá en los horarios que se mostraron en la sección anterior. A continuación se mostrará el calendario de disponibilidad, favor de seleccionar una semana, la que sea de su preferencia, para asistir al Programa de Formación.
                                            </p>
                                        </div>
                                        <div class="col-12 mt-5">
                                            <date-picker
                                                v-model="weekDate"
                                                type="week"
                                                placeholder="Selecciona la semana"
                                                :disabled-date="weeks"
                                            ></date-picker>
                                            <label class="error" v-if="this.errors.weekDate">
                                                {{ this.errors.weekDate }}
                                            </label>
                                        </div>
                                        <div class="col-12 mt-5">
                                            <span class="mb-2 d-block">Número de personas que asistirán al Programa de Formación:<i class="t-faded-red">*</i></span>
                                        </div>
                                        <div class="col-12 mt-3">
                                            <span class="mb-2 d-block">Número de asistentes de sexo femenino:</span>
                                            <input type="number" v-model="female_attendees" value="" />
                                            <label class="error" v-if="this.errors.female_attendees">
                                                {{ this.errors.female_attendees }}
                                            </label>
                                        </div>
                                        <div class="col-12 mt-3">
                                            <span class="mb-2 d-block">Número de asistentes de sexo masculino:</span>
                                            <input type="number" v-model="male_attendees" value="" />
                                            <label class="error" v-if="this.errors.male_attendees">
                                                {{ this.errors.male_attendees }}
                                            </label>
                                        </div>
                                        <div class="col-12 mt-3">
                                            <span class="mb-2 d-block">Perfil de las personas asistentes:</span>
                                            <input type="number" v-model="attendees_profile" value="" />
                                            <label class="error" v-if="this.errors.attendees_profile">
                                                {{ this.errors.attendees_profile }}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-lg-6 col-12">3 de 5</div>
                                        <div class="col-lg-6 col-12 text-right">
                                            <button type="button" class="button t-white b-perrywinkle" @click.prevent="prev"><i class="fas fa-angle-left"></i> Regresar</button>
                                            <button type="button" class="button t-white b-amber" @click.prevent="next">Continuar <i class="fas fa-angle-right"></i></button>
                                        </div>
                                    </div>
                                </div>

                                <!-- TAB -- 4 -->
                                <div class="tab" v-if="step === 4">
                                    <div class="diagnose-title">Información General de la Empresa</div>
                                    <div class="row">
                                        <div class="col-lg-6 col-12">
                                            <span>Nombre de la Empresa <i class="f-w400">(como aparece en el acta constitutiva)</i><i class="t-faded-red">*</i></span>
                                            <input type="text" v-model="business_name">
                                            <label class="error" v-if="this.errors.business_name">
                                                {{ this.errors.business_name }}
                                            </label>
                                        </div>
                                        <div class="col-lg-6 col-12">
                                            <span>Giro comercial<i class="t-faded-red">*</i></span>
                                            <input type="text" v-model="commercial_business">
                                            <label class="error" v-if="this.errors.commercial_business">
                                                {{ this.errors.commercial_business }}
                                            </label>
                                        </div>
                                        <div class="col-lg-6 col-12">
                                            <span>Sector<i class="t-faded-red">*</i></span>
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" class="custom-control-input" v-model="sector" id="sector-private" value="1" >
                                                <label class="custom-control-label" for="sector-private">Privado</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" class="custom-control-input" v-model="sector" id="sector-social" value="2">
                                                <label class="custom-control-label" for="sector-social">Social</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" class="custom-control-input" v-model="sector" id="sector-other" value="3">
                                                <label class="custom-control-label" for="sector-other">Otro</label>
                                                <label class="error" v-if="this.errors.sector">
                                                    {{ this.errors.sector }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-12">
                                            <span>Nombre del representante legal</span>
                                            <input type="text" v-model="legal_contact">
                                            <label class="error" v-if="this.errors.legal_contact">
                                                {{ this.errors.legal_contact }}
                                            </label>
                                        </div>
                                        <div class="col-lg-6 col-12">
                                            <span>R.F.C.</span>
                                            <input type="text" v-model="rfc">
                                            <label class="error" v-if="this.errors.rfc">
                                                {{ this.errors.rfc }}
                                            </label>
                                        </div>
                                        <div class="col-lg-6 col-12">
                                            <span>Página de internet</span>
                                            <input type="text" v-model="web">
                                            <label class="error" v-if="this.errors.web">
                                                {{ this.errors.web }}
                                            </label>
                                        </div>
                                        <div class="col-lg-6 col-12">
                                            <span>Teléfono<i class="t-faded-red">*</i></span>
                                            <input type="tel" v-model="phone">
                                            <label class="error" v-if="this.errors.phone">
                                                {{ this.errors.phone }}
                                            </label>
                                        </div>
                                        <div class="col-lg-6 col-12">
                                            <span>Domicilio<i class="t-faded-red">*</i></span>
                                            <input type="text" v-model="address_line">
                                            <label class="error" v-if="this.errors.address_line">
                                                {{ this.errors.address_line }}
                                            </label>
                                        </div>
                                        <div class="col-lg-6 col-12">
                                            <span>Colonia<i class="t-faded-red">*</i></span>
                                            <input type="text" v-model="address_line_2">
                                            <label class="error" v-if="this.errors.address_line_2">
                                                {{ this.errors.address_line_2 }}
                                            </label>
                                        </div>
                                        <div class="col-lg-6 col-12">
                                            <span>Ciudad/Municipio<i class="t-faded-red">*</i></span>
                                            <input type="text" v-model="city">
                                            <label class="error" v-if="this.errors.city">
                                                {{ this.errors.city }}
                                            </label>
                                        </div>
                                        <div class="col-lg-6 col-12">
                                            <span>Código postal<i class="t-faded-red">*</i></span>
                                            <input type="text" v-model="postal_code">
                                            <label class="error" v-if="this.errors.postal_code">
                                                {{ this.errors.postal_code }}
                                            </label>
                                        </div>
                                        <div class="col-lg-6 col-12">
                                            <span>Personas colaboradoras mujeres<i class="t-faded-red">*</i></span>
                                            <div class="row">
                                                <div class="col-lg-6 col-12">
                                                    <span class="f-w400">Tiempo completo</span>
                                                    <input type="number" v-model="female_full_time">
                                                    <label class="error" v-if="this.errors.female_full_time">
                                                        {{ this.errors.female_full_time }}
                                                    </label>
                                                </div>
                                                <div class="col-lg-6 col-12">
                                                    <span class="f-w400">Medio tiempo</span>
                                                    <input type="number" v-model="female_part_time">
                                                    <label class="error" v-if="this.errors.female_part_time">
                                                        {{ this.errors.female_part_time }}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-12">
                                            <span>Personas colaboradoras hombres<i class="t-faded-red">*</i></span>
                                            <div class="row">
                                                <div class="col-lg-6 col-12">
                                                    <span class="f-w400">Tiempo completo</span>
                                                    <input type="number" v-model="male_full_time">
                                                    <label class="error" v-if="this.errors.male_full_time">
                                                        {{ this.errors.male_full_time }}
                                                    </label>
                                                </div>
                                                <div class="col-lg-6 col-12">
                                                    <span class="f-w400">Medio tiempo</span>
                                                    <input type="number" v-model="male_part_time">
                                                    <label class="error" v-if="this.errors.male_part_time">
                                                        {{ this.errors.male_part_time }}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-lg-6 col-12">4 de 5</div>
                                        <div class="col-lg-6 col-12 text-right">
                                            <button type="button" class="button t-white b-perrywinkle" @click.prevent="prev"><i class="fas fa-angle-left"></i> Regresar</button>
                                            <button type="button" class="button t-white b-amber" @click.prevent="next">Continuar <i class="fas fa-angle-right"></i></button>
                                        </div>
                                    </div>
                                </div>

                                <!-- TAB -- 5 -->

                                <div class="tab" v-if="step === 5">
                                    <div class="diagnose-title">Datos de la Persona Enlace</div>
                                    <div class="row">
                                        <div class="col-lg-6 col-12">
                                            <span>Nombre del contacto<i class="t-faded-red">*</i></span>
                                            <input type="text" v-model="contact_name">
                                            <label class="error" v-if="this.errors.contact_name">
                                                {{ this.errors.contact_name }}
                                            </label>
                                        </div>
                                        <div class="col-lg-6 col-12">
                                            <span>Cargo<i class="t-faded-red">*</i></span>
                                            <input type="text" v-model="contact_job">
                                            <label class="error" v-if="this.errors.contact_job">
                                                {{ this.errors.contact_job }}
                                            </label>
                                        </div>
                                        <div class="col-lg-6 col-12">
                                            <span>Teléfono<i class="t-faded-red">*</i></span>
                                            <input type="tel" v-model="contact_phone">
                                            <label class="error" v-if="this.errors.contact_phone">
                                                {{ this.errors.contact_phone }}
                                            </label>
                                        </div>
                                        <div class="col-lg-6 col-12">
                                            <span>Correo electrónico<i class="t-faded-red">*</i></span>
                                            <input type="email" v-model="email">
                                            <label class="error" v-if="this.errors.email">
                                                {{ this.errors.email }}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-lg-6 col-12">5 de 5</div>
                                        <div class="col-lg-6 col-12 text-right">
                                            <button type="button" class="button t-white b-perrywinkle" @click.prevent="prev"><i class="fas fa-angle-left"></i> Regresar</button>
                                            <button type="button" class="button t-white b-green-blue" @click.prevent="submit">Registrarse <i class="fas fa-angle-right"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
    name : "TrainingComponent",
    components : {
        DatePicker,
    },
    props : {
        apiUrl :   {
            required    :   true,
        }
    },
    data() {
        return {
            step                :   -1,
            more_info           :   '',
            register            :   '',
            female_attendees    :   '',
            male_attendees      :   '',
            attendees_profile   :   '',
            privacy             :   '',
            business_name       :   '',
            legal_contact       :   '',
            web                 :   '',
            rfc                 :   '',
            address_line        :   '',
            address_line_2      :   '',
            city                :   '',
            postal_code         :   '',
            commercial_business :   '',
            sector              :   '',
            female_full_time    :   '',
            female_part_time    :   '',
            male_full_time      :   '',
            male_part_time      :   '',
            contact_name        :   '',
            contact_job         :   '',
            phone               :   '',
            contact_phone       :   '',
            email               :   '',
            weekDate            :   null,
            errors              :   {},
        }
    },
    methods : {
        prev() {
            this.step--;
            window.scrollTo(0,0);
        },
        next() {
            if (this.validate(this.step)) {
                if (this.step === 1 && this.register === "1"){
                    window.location.href = '/registro';
                }
                this.step++;
                window.scrollTo(0, 0);
            }
        },
        submit() {
            if (this.validate(this.step)){
                axios({
                    method: 'post',
                    data: {
                        more_info           :   this.more_info,
                        female_attendees    :   this.female_attendees,
                        male_attendees      :   this.male_attendees,
                        attendees_profile   :   this.attendees_profile,
                        privacy             :   this.privacy,
                        business_name       :   this.business_name,
                        legal_contact       :   this.legal_contact,
                        web                 :   this.web,
                        rfc                 :   this.rfc,
                        address_line        :   this.address_line,
                        address_line_2      :   this.address_line_2,
                        city                :   this.city,
                        postal_code         :   this.postal_code,
                        commercial_business :   this.commercial_business,
                        sector              :   this.sector,
                        female_full_time    :   this.female_full_time,
                        female_part_time    :   this.female_part_time,
                        male_full_time      :   this.male_full_time,
                        male_part_time      :   this.male_part_time,
                        contact_name        :   this.contact_name,
                        contact_job         :   this.contact_job,
                        phone               :   this.phone,
                        contact_phone       :   this.contact_phone,
                        email               :   this.email,
                        week                :   this.weekDate
                    },
                    url: this.apiUrl + '/api/registro',
                }).then(response => {
                    window.location.href = '/registro-formacion/gracias';
                }).catch(
                        error => {
                            if (error.response.status === 422) {
                                this.errors = error.response.data.errors || {};
                            }
                        }
                    );
            }
        },
        validate(step){
            this.errors = {};
            switch (step) {
                case 1:
                    if (!this.more_info) {
                        this.errors.more_info= 'Este campo es requerido';
                    }
                    if (!this.register) {
                        this.errors.register= 'Este campo es requerido';
                    }
                    break;
                case 2:
                    if (!this.privacy) {
                        this.errors.privacy= 'Este campo es requerido';
                    }
                    break;
                case 3:
                    if (!this.weekDate) {
                        this.errors.weekDate= 'Este campo es requerido';
                    }
                    if (!this.female_attendees) {
                        this.errors.female_attendees= 'Este campo es requerido';
                    }
                    if (!this.male_attendees) {
                        this.errors.male_attendees= 'Este campo es requerido';
                    }
                    if (!this.attendees_profile) {
                        this.errors.attendees_profile= 'Este campo es requerido';
                    }
                    break;
                case 4:
                    if (!this.business_name) {
                        this.errors.business_name= 'Este campo es requerido';
                    }
                    if (this.rfc) {
                        if (!this.validRFC(this.rfc)) {
                            this.errors.rfc = 'El RFC no es válido';
                        }
                    }
                    if (!this.address_line) {
                        this.errors.address_line= 'Este campo es requerido';
                    }
                    if (!this.address_line_2) {
                        this.errors.address_line_2= 'Este campo es requerido';
                    }
                    if (!this.city) {
                        this.errors.city= 'Este campo es requerido';
                    }
                    if (!this.postal_code) {
                        this.errors.postal_code= 'Este campo es requerido';
                    } else if (!this.validPostalCode(this.postal_code)) {
                        this.errors.postal_code= 'El código postal no es válido'
                    }
                    if (!this.commercial_business) {
                        this.errors.commercial_business= 'Este campo es requerido';
                    }
                    if (!this.sector) {
                        this.errors.sector= 'Este campo es requerido';
                    }
                    if (!this.female_full_time) {
                        this.errors.female_full_time= 'Este campo es requerido';
                    }
                    if (!this.female_part_time) {
                        this.errors.female_part_time= 'Este campo es requerido';
                    }
                    if (!this.male_full_time) {
                        this.errors.male_full_time= 'Este campo es requerido';
                    }
                    if (!this.male_part_time) {
                        this.errors.male_part_time= 'Este campo es requerido';
                    }
                    break;
                case 5:
                    if (!this.contact_name) {
                        this.errors.contact_name= 'Este campo es requerido';
                    }
                    if (!this.contact_job) {
                        this.errors.contact_job= 'Este campo es requerido';
                    }
                    if (!this.contact_phone) {
                        this.errors.contact_phone= 'Este campo es requerido';
                    }
                    if (!this.email) {
                        this.errors.email= 'Este campo es requerido';
                    } else if (!this.validEmail(this.email)) {
                        this.errors.email= 'El correo electrónico debe ser válido'
                    }
                    break;
            }
            return (Object.keys(this.errors).length === 0);
        },
        validRFC(rfc) {
          let re = /^([A-ZÑ&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{3})$/i ;
          return re.test(rfc);
        },
        validEmail(email) {
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        validPostalCode(postalCode) {
          let re = /^[0-9]{5}$/i;
          return re.test(postalCode);
        },
        weeks(date) {
            let numberOfDays =(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - Date.UTC(date.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000;
            let week = date.getDay() === 6 ? Math.floor((numberOfDays + 6) / 7) : Math.ceil((numberOfDays + 6) / 7);
            return week === 30 || week === 47 || week === 50 || week === 51 || date < new Date();
        },
    }
}
</script>

<style scoped>

</style>
