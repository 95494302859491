<template>
    <div class="container-fluid">
        <div class="row">
            <hooper
                :settings="hooperSettings"
                :centerMode="true">
                <slide>
                    <button class="images number__1" @click="pacto=1">
                        <div class="col-12">
                            <h2 class="t-dark ff-nutB f-s45 text-left">PACTO</h2>
                            <div class="offset-4 col-7">
                                <img src="images/pacto-big-1.svg" alt="numero uno imagen" class="img-fluid text-right">
                            </div>
                        </div>
                    </button>
                </slide>

                <slide class="mx-sm-3 mx-0 p__left">
                        <button class="images number__2" @click="pacto=2">
                            <div class="col-12">
                                <h2 class="t-dark ff-nutB f-s45 text-left">PACTO</h2>
                                <div class="offset-3 col-9">
                                    <img src="images/pacto-big-2.svg" alt="numero dos imagen" class="img-fluid text-right">
                                </div>
                            </div>
                        </button>
                </slide>

                <slide>
                        <button class="images py-5 number__3" @click="pacto=3">
                            <div class="col-12 col-md text-right">
                                <h2 class="t-dark ff-nutB f-s45 text-left ">PACTO</h2>
                                <div class="offset-3 col-9">
                                    <img src="images/pacto-big-3.svg" alt="tres imagen" class="img-fluid">
                                </div>
                            </div>
                        </button>
                </slide>

                <slide>
                    <button class="images py-5 number__4" @click="pacto=4">
                        <div class="col-12 text-right">
                            <h2 class="t-dark ff-nutB f-s45 text-left ">PACTO</h2>
                            <div class="offset-3 col-9">
                                <img src="images/pacto-big-4.svg" alt="tres imagen" class="img-fluid">
                            </div>
                        </div>
                    </button>
                </slide>

                <slide>
                    <button class="images py-5 number__5" @click="pacto=5">
                        <div class="col-12 text-right">
                            <h2 class="t-dark ff-nutB f-s45 text-left ">PACTO</h2>
                            <div class="offset-3 col-9">
                                <img src="images/pacto-big-5.svg" alt="tres imagen" class="img-fluid">
                            </div>
                        </div>
                    </button>
                </slide>

            </hooper>

            <div class="row justify-content-center no-gutters bg-lig m__top" v-if="pacto===1">
                <div class="col-10 col-sm-12 my-5 py-5">
                    <div class="row py-5 no-gutters">
                        <div class="col-12 col-sm-5 t-dark">
                            <h2 class="ff-nutBL f-s60 text-left pl-3">Política Igualitaria</h2>
                            <img src="images/pactos/pacto-1.svg" alt="Política Igualitaria" class="img-fluid">
                        </div>
                        <div class="col-12 col-sm-5 offset-md-1 b-top-dark-7 f-s17 text-left">
                            <div class="row mt-3 pt-5 no-gutters">
                                <div class="col-12 col-sm-12 t-dark">
                                    <h2 class="ff-nutBL f-s25">Presentación</h2>
                                    <p>
                                        El primer paso para hacer de la igualdad de género una realidad en el espacio laboral es la implementación de una política igualitaria.
                                    </p>
                                    <p>
                                        Esto significa crear la estructura que sostiene la implementación de todas las estrategias necesarias para construir espacios laborales más igualitarios.
                                    </p>
                                </div>
                                <div class="col-12 col-sm-12 t-dark f-s17">
                                    <h2 class="ff-nutBL f-s25">Acciones</h2>
                                    <div class="acciones row mb-4 no-gutters">
                                        <div class="col-3">
                                            <div class="acciones_img">
                                                <img src="images/acciones/01-establecer-la-existencia-de-una-persona-enlace-y-un-comite-de-igualdad.png" alt="" class="img-fluid">
                                            </div>
                                        </div>
                                        <div class="col-9 p__left">
                                            <div class="acciones_text">
                                                <p><strong>1.</strong> Establecer la existencia de una Persona Enlace y un Comité de Igualdad.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="acciones row mb-4 no-gutters">
                                        <div class="col-3">
                                            <div class="acciones_img">
                                                <img src="images/acciones/02-medir-el-clima-laboral.png" alt="" class="img-fluid">
                                            </div>
                                        </div>
                                        <div class="col-9 p__left">
                                            <div class="acciones_text">
                                                <p><strong>2.</strong> Medir el clima laboral.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="acciones row mb-4 no-gutters">
                                        <div class="col-3">
                                            <div class="acciones_img">
                                                <img src="images/acciones/03-realizar-una-campaña-sobre-igualdad-de-genero.png" alt="" class="img-fluid">
                                            </div>
                                        </div>
                                        <div class="col-9 p__left">
                                            <div class="acciones_text">
                                                <p><strong>3.</strong> Realizar una campaña sobre igualdad de género.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="acciones row mb-4 no-gutters">
                                        <div class="col-3">
                                            <div class="acciones_img">
                                                <img src="images/acciones/04-utilizar-lenguaje-no-sexista-y-no-discriminatorio.png" alt="" class="img-fluid">
                                            </div>
                                        </div>
                                        <div class="col-9 p__left">
                                            <div class="acciones_text">
                                                <p><strong>4.</strong> Utilizar lenguaje incluyente y no sexista.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="acciones row mb-4 no-gutters">
                                        <div class="col-3">
                                            <div class="acciones_img">
                                                <img src="images/acciones/05-elaborar-un-plan-de-seguimiento-de-los-5-pactos.png" alt="" class="img-fluid">
                                            </div>
                                        </div>
                                        <div class="col-9 p__left">
                                            <div class="acciones_text">
                                                <p><strong>5.</strong> Elaborar un plan de seguimiento de los 5 pactos.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <h2 class="ff-nutBL f-s25 mb-4">ADICIONAL A QUIENES DAN ATENCIÓN AL PÚBLICO:</h2>
                                    <div class="acciones row mb-4 no-gutters">
                                        <div class="col-3">
                                            <div class="acciones_img">
                                                <img src="images/acciones/06-implementar-lineamientos-de-atencion-libre-de-discriminacion-de-genero.png" alt="" class="img-fluid">
                                            </div>
                                        </div>
                                        <div class="col-9 p__left">
                                            <div class="acciones_text">
                                                <p><strong>6.</strong> Implementar lineamientos de atención libre de discriminación de género.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row justify-content-center no-gutters bg-lig m__top" v-else-if="pacto===2">
                <div class="col-10 col-sm-12 my-5">
                <div class="row py-5 no-gutters">
                    <div class="col-12 col-sm-5 col-lg-4 t-dark">
                        <h2 class="ff-nutBL f-s60 text-left pl-3">Espacios seguros</h2>
                        <img src="images/pactos/pacto-2.svg" alt="Espacios seguros" class="img-fluid">
                    </div>
                    <div class="col-12 col-sm-5 col-lg-7 offset-md-1 b-top-dark-7 f-s17 text-left">
                        <div class="row mt-3 pt-5 no-gutters">
                            <div class="col-sm-12 col-12 t-dark">
                                <h2 class="ff-nutBL f-s25">Presentación</h2>
                                <p>
                                    La <strong>violencia sexual en el ámbito laboral</strong> afecta principalmente a mujeres y se expresa en acoso y hostigamiento sexual. Por lo general, las personas deciden no hablar sobre lo ocurrido por miedo a los prejuicios y por <strong>falta de mecanismos claros</strong> para atender estas situaciones. Por esta razón, es fundamental que los centros de trabajo dediquen esfuerzos a <strong>prevenir y atender el acoso y el hostigamiento sexual</strong>.
                                </p>
                            </div>
                            <div class="col-sm-12 col-12 t-dark f-s17">
                                <h2 class="ff-nutBL f-s25">Acciones</h2>
                                <div class="acciones row mb-4 no-gutters">
                                    <div class="col-3">
                                        <div class="acciones_img">
                                            <img src="images/acciones/07-establecer-mecanismos-de-respuesta-ante-presuntos-casos-de-acoso-y-hostigamiento-sexual.png" alt="" class="img-fluid">
                                        </div>
                                    </div>
                                    <div class="col-9 p__left">
                                        <div class="acciones_text">
                                            <p>
                                                <strong>7.</strong>
                                                Establecer mecanismos de respuesta ante presuntos casos de acoso y hostigamiento sexual.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="acciones row mb-4 no-gutters">
                                    <div class="col-3">
                                        <div class="acciones_img">
                                            <img src="images/acciones/08-realizar-una-campaña-constante-para-concientizar-sobre-el-acoso-y-hostigamiento-sexual.png" alt="" class="img-fluid">
                                        </div>
                                    </div>
                                    <div class="col-9 p__left">
                                        <div class="acciones_text">
                                            <p>
                                                <strong>8.</strong>
                                                Realizar una campaña constante para concientizar sobre el acoso y hostigamiento sexual.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>

            <div class="row justify-content-center no-gutters bg-lig m__top" v-else-if="pacto===3">
                <div class="col-10 col-sm-12 my-5 py-5">
                    <div class="row py-5 no-gutters">
                        <div class="col-12 col-sm-5 col-lg-4 t-dark">
                            <h2 class="ff-nutBL f-s60 text-left pl-3">Vida y Trabajo en Equilibrio</h2>
                            <img src="images/pactos/pacto-3.svg" alt="imagen espacios seguros" class="img-fluid">
                        </div>
                        <div class="col-12 col-sm-5 col-lg-7 offset-md-1 b-top-dark-7 f-s17 text-left">
                            <div class="row mt-3 pt-5 no-gutters">
                                <div class="col-12 t-dark">
                                    <h2 class="ff-nutBL f-s25">Presentación</h2>
                                    <p>
                                        En una sociedad en la que las tareas del cuidado de la casa, de personas adultas mayores, de personas con discapacidad y la crianza de niños y niñas recaen principalmente en las mujeres, la forma en la que se organizan las jornadas de trabajo tienen un papel importante en la exclusión o inclusión de mujeres del ámbito laboral, y a su vez, en desincentivar o motivar que los hombres participen de forma activa e igualitaria en las tareas antes mencionadas.
                                    </p>
                                    <p>
                                        En pocas palabras, organizar el trabajo de manera que la conciliación de la vida laboral y personal se vea favorecida, deriva en incrementar la igualdad entre mujeres y hombres.
                                    </p>
                                </div>
                                <div class="col-12 t-dark f-s17">
                                    <h2 class="ff-nutBL f-s25">Acciones</h2>
                                    <div class="acciones row mb-4 no-gutters">
                                        <div class="col-3">
                                            <div class="acciones_img">
                                                <img src="images/acciones/09-establecer-horarios-laborales-productivos-a-partir-de-los-modelos-de-flexibilidad,-teletrabajo,-escalonamiento,-jornadas-reducidas-y_o-semanas-compactadas.png" alt="" class="img-fluid">
                                            </div>
                                        </div>
                                        <div class="col-9 p__left">
                                            <div class="acciones_text">
                                                <p>
                                                    <strong>9.</strong>
                                                    Establecer horarios laborales productivos a partir de los modelos de: flexibilidad, teletrabajo, escalonamiento, jornadas reducidas y/o semanas compactadas.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="acciones row mb-4 no-gutters">
                                        <div class="col-3">
                                            <div class="acciones_img">
                                                <img src="images/acciones/10-explicitar-mecanismos-flexibles-para-acceder-a-licencias-de-maternidad-y-paternidad.png" alt="" class="img-fluid">
                                            </div>
                                        </div>
                                        <div class="col-9 p__left">
                                            <div class="acciones_text">
                                                <p>
                                                    <strong>10.</strong>
                                                    Explicitar mecanismos flexibles para acceder a licencias de maternidad y paternidad.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="acciones row mb-4 no-gutters">
                                        <div class="col-3">
                                            <div class="acciones_img">
                                                <img src="images/acciones/11-promover-la-maternidad-y-paternidad-corresponsable-y-la-division-igualitaria-de-responsabilidades-del-hogar.png" alt="" class="img-fluid">
                                            </div>
                                        </div>
                                        <div class="col-9 p__left">
                                            <div class="acciones_text">
                                                <p>
                                                    <strong>11.</strong>
                                                    Promover la maternidad y paternidad corresponsable y la división igualitaria de responsabilidades del hogar.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="acciones row mb-4 no-gutters">
                                        <div class="col-3">
                                            <div class="acciones_img">
                                                <img src="images/acciones/12-establecer-lineamientos-e-instalaciones-que-atienda-la-lactancia-materna.png" alt="" class="img-fluid">
                                            </div>
                                        </div>
                                        <div class="col-9 p__left">
                                            <div class="acciones_text">
                                                <p>
                                                    <strong>12.</strong>
                                                    Establecer lineamientos e instalaciones que atiendan la lactancia materna.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <h2 class="ff-nutBL f-s25 mb-4">ADICIONAL A QUIENES DAN ATENCIÓN AL PÚBLICO:</h2>
                                    <div class="acciones row mb-4 no-gutters">
                                        <div class="col-3">
                                            <div class="acciones_img">
                                                <img src="images/acciones/13-contar-con-cambiadores-para-bebe-disponibles-en-sanitarios-para-mujeres-y-hombres-o-familiares.png" alt="" class="img-fluid">
                                            </div>
                                        </div>
                                        <div class="col-9 p__left">
                                            <div class="acciones_text">
                                                <p>
                                                    <strong>13.</strong>
                                                    Contar con cambiadores para bebé disponibles en sanitarios para mujeres y hombres, o familiares.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row justify-content-center no-gutters bg-lig m__top" v-else-if="pacto===4">
                    <div class="col-10 col-sm-12 my-5 py-5">
                        <div class="row py-5 no-gutters">
                            <div class="col-12 col-sm-5 col-lg-4 t-dark">
                                <h2 class="ff-nutBL f-s60 text-left pl-3">Carrera sin Obstáculos</h2>
                                <img src="images/pactos/pacto-4.svg" alt="Carrera sin Obstáculos" class="img-fluid">
                            </div>
                            <div class="col-12 col-sm-5 col-lg-7 offset-md-1 b-top-dark-7 f-s17 text-left">
                                <div class="row mt-3 pt-5 no-gutters">
                                    <div class="col-12 t-dark">
                                        <h2 class="ff-nutBL f-s25">Presentación</h2>
                                        <p>
                                            La igualdad de género no es únicamente una cuestión de buenas intenciones, para realmente garantizar el trabajo justo e igualitario se tienen que revisar y ajustar todos los procesos internos, desde la contratación hasta el despido, así como las promociones. En cada uno de estos procesos, los estereotipos de género juegan un rol fundamental en la exclusión de las mujeres aunque no haya una intención explícita de hacerlo.
                                        </p>
                                    </div>
                                    <div class="col-12 t-dark f-s17">
                                        <h2 class="ff-nutBL f-s25">Acciones</h2>
                                        <div class="acciones row mb-4 no-gutters">
                                            <div class="col-3">
                                                <div class="acciones_img">
                                                    <img src="images/acciones/14-eliminar-requisitos-y-practicas-discriminatorias-en-entrevistas-de-trabajo-y-en-anuncios-de-vacantes.png" alt="" class="img-fluid">
                                                </div>
                                            </div>
                                            <div class="col-9 p__left">
                                                <div class="acciones_text">
                                                    <p>
                                                        <strong>14.</strong>
                                                        Eliminar requisitos y prácticas discriminatorias en entrevistas de trabajo y en anuncios de vacantes.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="acciones row mb-4 no-gutters">
                                            <div class="col-3">
                                                <div class="acciones_img">
                                                    <img src="images/acciones/15-impulsar-medidas-para-asegurar-el-cumplimiento-de-la-igualdad-salarial.png" alt="" class="img-fluid">
                                                </div>
                                            </div>
                                            <div class="col-9 p__left">
                                                <div class="acciones_text">
                                                    <p>
                                                        <strong>15.</strong>
                                                        Impulsar medidas para asegurar el cumplimiento de la igualdad salarial.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="acciones row mb-4 no-gutters">
                                            <div class="col-3">
                                                <div class="acciones_img">
                                                    <img src="images/acciones/16-contar-con-programas-de-crecimiento-laboral-con-perspectiva-de-genero-para-todo-el-personal-independientemente-de-su-nivel-de-responsabilidad.png" alt="" class="img-fluid">
                                                </div>
                                            </div>
                                            <div class="col-9 p__left">
                                                <div class="acciones_text">
                                                    <p>
                                                        <strong>16.</strong>
                                                        Contar con programas de crecimiento laboral con perspectiva de género para todo el personal independientemente de su nivel de responsabilidad.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="acciones row mb-4 no-gutters">
                                            <div class="col-3">
                                                <div class="acciones_img">
                                                    <img src="images/acciones/17-transparentar-requisitos-para-acceder-a-ascensos-y-aumentos-de-sueldo.png" alt="" class="img-fluid">
                                                </div>
                                            </div>
                                            <div class="col-9 p__left">
                                                <div class="acciones_text">
                                                    <p>
                                                        <strong>17.</strong>
                                                        Transparentar requisitos para acceder a ascensos y aumentos de sueldo.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="acciones row mb-4 no-gutters">
                                            <div class="col-3">
                                                <div class="acciones_img">
                                                    <img src="images/acciones/18-explicitar-mecanismos-para-acceder-a-los-bonos-e-incentivos-existentes.png" alt="" class="img-fluid">
                                                </div>
                                            </div>
                                            <div class="col-9 p__left">
                                                <div class="acciones_text">
                                                    <p>
                                                        <strong>18.</strong>
                                                        Explicitar mecanismos para acceder a los bonos e incentivos existentes.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="acciones row mb-4 no-gutters">
                                            <div class="col-3">
                                                <div class="acciones_img">
                                                    <img src="images/acciones/19-establecer-un-procedimiento-para-atender-inconformidades-de-condiciones-laborales.png" alt="" class="img-fluid">
                                                </div>
                                            </div>
                                            <div class="col-9 p__left">
                                                <div class="acciones_text">
                                                    <p>
                                                        <strong>19.</strong>
                                                        Establecer un procedimiento para atender inconformidades de condiciones laborales.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="acciones row mb-4 no-gutters">
                                            <div class="col-3">
                                                <div class="acciones_img">
                                                    <img src="images/acciones/20-cumplir-con-el-checklist-de-despidos-libre-de-discriminacion-de-genero.png" alt="" class="img-fluid">
                                                </div>
                                            </div>
                                            <div class="col-9 p__left">
                                                <div class="acciones_text">
                                                    <p>
                                                        <strong>20.</strong>
                                                        Cumplir con el Checklist de Despidos Libre de Discriminación de Género.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            <div class="row justify-content-center no-gutters bg-lig m__top" v-else-if="pacto===5">
                    <div class="col-10 col-sm-12 my-5 py-5">
                        <div class="row py-5 no-gutters">
                            <div class="col-12 col-sm-5 col-lg-4 t-dark">
                                <h2 class="ff-nutBL f-s60 text-left pl-3">Agentes de Igualdad</h2>
                                <img src="images/pactos/pacto-5.svg" alt="Agentes de Igualdad" class="img-fluid">
                            </div>
                            <div class="col-12 col-sm-5 col-lg-7 offset-md-1 b-top-dark-7 f-s17 text-left">
                                <div class="row mt-3 pt-5 no-gutters">
                                    <div class="col-12 t-dark">
                                        <h2 class="ff-nutBL f-s25">Presentación</h2>
                                        <p>
                                            Un elemento clave para promover una cultura de igualdad recae en el compromiso que el centro de trabajo muestra hacia el tema. Esto se traduce en que la Alta Dirección haga de la igualdad de género una parte esencial de la visión y misión del centro de trabajo a través del compromiso de sus líderes y de actividades que promuevan una cultura de la igualdad entre todo el personal, así como en su ámbito de influencia.
                                        </p>
                                    </div>
                                    <div class="col-12 t-dark f-s17">
                                        <h2 class="ff-nutBL f-s25">Acciones</h2>
                                        <div class="acciones row mb-4 no-gutters">
                                            <div class="col-3">
                                                <div class="acciones_img">
                                                    <img src="images/acciones/21-realizar-actividades-o-eventos-para-el-personal-y-su-familia-que-promueven-la-igualdad-de-genero.png" alt="" class="img-fluid">
                                                </div>
                                            </div>
                                            <div class="col-9 p__left">
                                                <div class="acciones_text">
                                                    <p>
                                                        <strong>21.</strong>
                                                        Realizar actividades o eventos para el personal y su familia que promuevan la igualdad de género.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="acciones row mb-4 no-gutters">
                                            <div class="col-3">
                                                <div class="acciones_img">
                                                    <img src="images/acciones/22-promover-la-igualdad-de-genero-entre-su-ambito-de-influencia.png" alt="" class="img-fluid">
                                                </div>
                                            </div>
                                            <div class="col-9 p__left">
                                                <div class="acciones_text">
                                                    <p>
                                                        <strong>22.</strong>
                                                        Promover la igualdad de género  entre su ámbito de influencia.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

        </div>
    </div>
</template>


<script>
import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';
export default {
    name: 'App',
    components: {
        Hooper,
        Slide
    },
    data() {
        return {
            pacto: 1,
            hooperSettings: {
                breakpoints: {
                    1100: {
                        itemsToShow: 3.3
                    },
                    600: {
                        itemsToShow: 2.5
                    },
                    0: {
                        itemsToShow: 1.25
                    }
                }
            }

        }
    }
}
</script>

<style lang="scss" scoped>

     .acciones {

        &_img {
            width: 70px;
            height: 70px;
            background-color: #ffffff;
            border-radius: 10px;
        }

        &_text {
        }
    }

.bg-lig{
    background: #f7f7f7;
}
.m__top{
    margin-top: -42px;
    margin-bottom: -48px;
}
.images {
    height: 409px;
    width: 282px;
    align-items: center;
    justify-content: center;
}
.number__1 {
    background: #ff8571;
}
.number__2 {
    background: #91a3e3;
}
.number__3 {
    background: #05aa83;
}
.number__4 {
    background: #ffb404;
}
.number__5 {
    background: #ff5263;
}
.hooper{
    height: 450px;
}

.hooper-slide.is-current {
    transform: scale(1.2);
}

button {
    border: none;
}
</style>
