/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue').default;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/SliderComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('slider-component', require('./components/SliderComponent.vue').default);
Vue.component('training-component', require('./components/RegTrainingComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});


$(document).ready(function () {

    /* Menu display */
    $('.menu-show').click(function () {
        $('.menu').addClass('menu-active');
    });
    $('.menu-close_button').click(function (){
        $('.menu').removeClass('menu-active');
    });
    /*----- Menu display ------*/

    $(document).on('click', 'a[href^="#"]', function (event) {
        event.preventDefault();

        let anchor = $(this).attr('href');
        //console.log(anchor);

        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top
        }, 500);

        $('.menu').removeClass('menu-active');
    });

});

const swiper = new Swiper('.swiper', {
    slidesPerView: 3,
    spaceBetween: 5,
    centeredSlides: true,
    loop: true,
    autoplay: {
        delay: 2500,
        disableOnInteraction: false,
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    breakpoints: {
        990: {
            slidesPerView: 4,
            spaceBetween: 15,
        },
        1440: {
            slidesPerView: 5,
            spaceBetween: 30,
        },
    },
});
